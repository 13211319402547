<script setup lang="ts">
const Icon = useIcon();
import { OuterGearIcon } from 'assets/data/icons';
import { InnerGearIcon } from 'assets/data/icons';

interface IBindings {
  'stroke-width': string;
}

const bindings: IBindings = {
  'stroke-width': '2',
};
</script>

<template>
  <Icon
    :path="[OuterGearIcon, InnerGearIcon]"
    :size="27"
    :filled="false"
    class="w-8"
    :bindings="bindings"
    :stroked="true"
  />
</template>
